<template>
  <div>
    <!-- CSS -->
    <script type="text/html" id="survey-css">
      <div
        class="btn btn-primary sv-btn svd-toolbar-button svd_save_btn mb-3"
        role="button"
        title="Save CSS"
        data-bind="click:save"
      >
        <div class="svd-toolbar-button__title">Save Survey</div>
      </div>
      <textarea class="survey-css" data-bind="value: css" />
    </script>
    <!-- CSS -->
    <script type="text/html" id="survey-js">
      <div
        class="btn btn-primary sv-btn svd-toolbar-button svd_save_btn mb-3"
        role="button"
        title="Save JS"
        data-bind="click:save"
      >
        <div class="svd-toolbar-button__title">Save Survey</div>
      </div>
      <textarea class="survey-css" data-bind="value: js" />
    </script>
    <!-- Result -->
    <script type="text/html" id="survey-result">
      <div
        class="btn btn-primary sv-btn svd-toolbar-button svd_save_btn mb-3"
        role="button"
        title="Save Result"
        data-bind="click:save"
      >
        <div class="svd-toolbar-button__title">Save Survey</div>
      </div>
      <div class="survey-label">Success:</div>
      <textarea
        id="success"
        class="survey-result"
        data-bind="value: success"
      ></textarea>
      <div class="survey-label">Error:</div>
      <textarea
        id="error"
        class="survey-result"
        data-bind="value: error"
      ></textarea>
    </script>
    <!-- Creator -->
    <div id="surveyCreatorContainer"></div>
    <!-- Dialog -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Survey Info</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" :lazy-validation="false">
            <v-text-field
              v-model="form.name"
              :counter="50"
              :rules="[rules.required]"
              label="Name"
            ></v-text-field>

            <v-menu
              v-model="dstart"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.start"
                  label="Start"
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="form.start = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.start"
                @input="dstart = false"
              ></v-date-picker>
            </v-menu>

            <v-menu
              v-model="dstop"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.stop"
                  label="Stop"
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="form.stop = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.stop"
                @input="dstop = false"
              ></v-date-picker>
            </v-menu>

            <v-text-field
              v-model="form.callback"
              :rules="[rules.url]"
              label="Callback URL"
            ></v-text-field>

            <v-text-field
              v-model="form.approveURL"
              :rules="[rules.url]"
              label="Approve Callback URL"
            ></v-text-field>

            <v-row>
              <v-col cols="6" class="p-0">
                <v-switch v-model="form.active" inset label="Active"></v-switch>
              </v-col>
              <v-col cols="6" class="p-0">
                <v-switch
                  v-model="form.default"
                  inset
                  label="Default"
                ></v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-switch
                  v-model="form.editableCMS"
                  inset
                  label="CMS Editable"
                ></v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch
                  v-model="form.editable"
                  inset
                  label="Editable"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
          <v-btn color="blue darken-1" text @click="_save()" :disabled="!valid"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { SurveyCreator } from 'survey-creator-knockout'
import { components } from 'knockout'
import { CustomWidgetCollection, Serializer } from 'survey-core'
import * as SurveyCore from 'survey-core'
import * as widgets from 'surveyjs-widgets'
import {
  PropertyGridEditorCollection,
  SurveyQuestionEditorDefinition,
} from 'survey-creator-core'
import { Converter } from 'showdown'

import 'survey-core/survey.i18n.js'
import 'survey-creator-core/survey-creator-core.i18n.js'

import 'survey-core/defaultV2.css'
import 'survey-creator-core/survey-creator-core.css'

import Form from '@/store/models/Form'

moment.tz.setDefault('Asia/Bangkok')

Serializer.addProperty('question', 'tag:number')
Serializer.addProperty('question', {
  name: 'approve:switch',
  default: false,
  category: 'general',
})

widgets.ckeditor(SurveyCore)

const ckEditor = CustomWidgetCollection.Instance.getCustomWidgetByName('editor')
//Hide from toolbox
ckEditor.showInToolbox = false
PropertyGridEditorCollection.register({
  fit: function(prop) {
    return prop.type == 'html'
  },
  getJSON: function() {
    return {
      type: 'editor',
    }
  },
})
const questionDef = SurveyQuestionEditorDefinition.definition.question
//Modify Question Editor. Remove title from general and add it as a tab.
questionDef.tabs.push({ name: 'title', index: 1 })
const ind = questionDef.properties.indexOf('title')
if (ind > -1) questionDef.properties.splice(ind, 1)
//Create showdown markdown converter
const converter = new Converter()
function doMarkdown(survey, options) {
  //convert the markdown text to html
  let str = converter.makeHtml(options.text)
  if (str.indexOf('<p>') == 0) {
    //remove root paragraphs <p></p>
    str = str.substring(3)
    str = str.substring(0, str.length - 4)
  }
  //set html
  options.html = str
}

export default {
  name: 'survey-creator',
  props: {
    data: Object,
  },
  computed: {
    projId() {
      return this.$store
        .$db()
        .model('projId')
        .find(1)
    },
    project() {
      return this.$store
        .$db()
        .model('projects')
        .find(this.projId && this.projId.value)
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        url: (value) => {
          if (value) {
            const pattern = /^(https?):\/\/[^\s$.?#].[^\s]*$/
            return pattern.test(value) || 'Invalid URL.'
          } else {
            return true
          }
        },
      },
      dialog: false,
      valid: false,
      dmenu: false,
      form: {},
      dstart: false,
      dstop: false,
      json: null,
    }
  },
  mounted() {
    this.setSurvey()
  },
  methods: {
    setSurvey() {
      const parent = this

      function loadedSurveyTemplates() {
        return [
          {
            css: (parent.data && parent.data.css) || '',
            js: (parent.data && parent.data.js) || '',
            success: (parent.data && parent.data.success) || '',
            error: (parent.data && parent.data.error) || '',
          },
        ]
      }

      const options = {
        showLogicTab: true,
        // isAutoSave: true,
      }
      const creator = new SurveyCreator(options)

      creator.survey.onTextMarkdown.add(doMarkdown)
      creator.onDesignerSurveyCreated.add(function(editor, options) {
        options.survey.onTextMarkdown.add(doMarkdown)
      })
      creator.onTestSurveyCreated.add(function(editor, options) {
        options.survey.onTextMarkdown.add(doMarkdown)
      })

      const templatesPlugin = {
        activate: () => {},
        deactivate: () => {
          return true
        },
      }

      if (components.isRegistered('survey-css')) {
        components.unregister('survey-css')
      }
      components.register('survey-css', {
        viewModel: {
          createViewModel: () => {
            var model = {
              surveys: loadedSurveyTemplates(),
              css: (value) => value || '',
              save: function() {
                parent.data.css = this.css
                parent._open(true)
              },
            }
            return model
          },
        },
        template: `
            <div class="pa-5" style="width:100%">
              <h3 class="sd-title sd-page__title">Custom CSS</h3>
                <div data-bind="foreach: surveys">
                  <div>
                    <button class="sd-btn sd-btn--action mt-2" data-bind="click: $parent.save, text: 'Save survey'"></button>
                    <hr class="my-5"/>
                    <textarea class="survey-css" rows="10" data-bind="value: css"></textarea>
                  </div>
                </div>
            </div>
          `,
      })

      // CSS Tab
      creator.addPluginTab(
        'survey-css',
        templatesPlugin,
        'CSS',
        'survey-css',
        4
      )

      if (components.isRegistered('survey-js')) {
        components.unregister('survey-js')
      }
      components.register('survey-js', {
        viewModel: {
          createViewModel: () => {
            var model = {
              surveys: loadedSurveyTemplates(),
              js: (value) => value || '',
              save: function() {
                parent.data.js = this.js
                parent._open(true)
              },
            }
            return model
          },
        },
        template: `
            <div class="pa-5" style="width:100%">
              <h3 class="sd-title sd-page__title">Custom JS</h3>
                <div data-bind="foreach: surveys">
                  <div>
                    <button class="sd-btn sd-btn--action mt-2" data-bind="click: $parent.save, text: 'Save survey'"></button>
                    <hr class="my-5"/>
                    <textarea class="survey-css" rows="10" data-bind="value: js"></textarea>
                  </div>
                </div>
            </div>
          `,
      })

      // JS tab
      creator.addPluginTab('survey-js', templatesPlugin, 'JS', 'survey-js', 5)

      if (components.isRegistered('survey-result')) {
        components.unregister('survey-result')
      }
      components.register('survey-result', {
        viewModel: {
          createViewModel: () => {
            var model = {
              surveys: loadedSurveyTemplates(),
              success: (value) => value || '',
              error: (value) => value || '',
              save: function() {
                parent.data.success = this.success
                parent.data.error = this.error
                parent._open(true)
              },
            }
            return model
          },
        },
        template: `
          <div class="pa-5" style="width:100%">
            <h3 class="sd-title sd-page__title">Custom Result</h3>
              <div data-bind="foreach: surveys">
                <div>
                  <button class="sd-btn sd-btn--action mt-2" data-bind="click: $parent.save, text: 'Save survey'"></button>
                  <hr class="my-5"/>
                  <h2 class="sd-title mb-2">Success:</h2>
                  <textarea class="survey-css" rows="10" data-bind="value: success"></textarea>
                  <h2 class="sd-title mb-2">Error:</h2>
                  <textarea class="survey-css" rows="10" data-bind="value: error"></textarea>
                </div>
              </div>
          </div>
        `,
      })

      // Result tab
      creator.addPluginTab(
        'survey-result',
        templatesPlugin,
        'Result',
        'survey-result',
        6
      )

      // Set file option default store file as base64 to File
      const file = creator.toolbox.getItemByName('file')
      file.json = { type: 'file', storeDataAsText: false }

      creator.render('surveyCreatorContainer')
      creator.JSON = (this.data && this.data.json) || {}
      creator.saveSurveyFunc = function() {
        parent.json = this.JSON
        parent._open(true)
      }
    },
    inputDate(value) {
      if (value.length >= 2) {
        this.dmenu = false
      }
    },
    _open(value = false) {
      this.dialog = value
      if (value) {
        if (this.data && this.data.id) {
          // Edit
          const start =
            this.data.start && moment(this.data.start).format('YYYY-MM-DD')
          const stop =
            this.data.stop && moment(this.data.stop).format('YYYY-MM-DD')

          this.form = {
            id: this.data.id,
            name: this.data.name,
            start: (start && start != '0001-01-01' && start) || null,
            stop: (stop && stop != '0001-01-01' && stop) || null,
            callback: this.data.callback,
            approveURL: this.data.approveURL,
            active: this.data.active,
            default: this.data.default,
            editable: this.data.editable,
            editableCMS: this.data.editableCMS,
          }
        } else {
          // Create
          this.form = {
            callback: this.project && this.project.callback,
            approveURL: this.project && this.project.callback,
            active: true,
            default: true,
            editable: false,
            editableCMS: false,
          }
        }
      } else {
        this.form = {}
      }
    },
    _save() {
      if (!this.valid || !(this.projId && this.projId.value)) {
        return
      }

      const start = this.form.start
      const stop = this.form.stop
      const approve = JSON.stringify(this.json).includes('"approve":true')
      const data = {
        ...this.form,
        projId: this.projId.value,
        dates: undefined,
        start: start && moment(start, 'YYYY-MM-DD').startOf('day'),
        stop: stop && moment(stop, 'YYYY-MM-DD').endOf('day'),
        approve,
        json: this.json || (this.data && this.data.json),
        css: (this.data && this.data.css) || this.css || '',
        js: (this.data && this.data.js) || this.js || '',
        success: (this.data && this.data.success) || this.success || '',
        error: (this.data && this.data.error) || this.error || '',
      }

      let save
      const options = { data }
      if (this.form.id) {
        save = Form.update(this.form.id, options)
      } else {
        save = Form.create(options)
      }

      save
        .then(() => {
          this.dialog = false
          this.$emit('update:dialog', false)
          this.$emit('update:save', true)
        })
        .catch((err) => {
          this.dialog = false
          console.error('_save err: ', err)
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#surveyCreatorContainer {
  height: 100vh;
  width: 100%;
}

.survey-css {
  width: 100%;
  height: 50%;
  background-color: white;
  padding: 10px;
}

.survey-result {
  width: 100%;
  height: 30%;
  background-color: white;
  padding: 10px;
}

.survey-label {
  font-size: 16px;
  padding: 10px 0;
}
</style>
